// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.elevation-classes();
@include mat.app-background();

$md-palette: (
  50: #efe5fb,
  100: #d4c0f5,
  200: #b795ef,
  300: #9968ea,
  400: #8043e6,
  500: #6415e0,
  600: #590fda,
  700: #4600d2,
  800: #3000cc,
  900: #0000c5,
  contrast: (
    50: #f5f5f5,
    100: #e9e9e9,
    200: #d9d9d9,
    300: #c4c4c4,
    400: #9d9d9d,
    500: #7b7b7b,
    600: #555555,
    700: #434343,
    800: #262626,
    900: #000000,
  ),
);

$mdc-text-field-input-padding: 8px;

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$website-primary: mat.m2-define-palette(mat.$m2-gray-palette);
$website-accent: mat.m2-define-palette($md-palette, 200, 100, 400);

// The warn palette is optional (defaults to red).
$website-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$website-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $website-primary,
      accent: $website-accent,
      warn: $website-warn,
    ),
    typography:
      mat.m2-define-typography-config(
        $font-family:
          '"ABCCameraPlain","ABCCameraPlain Fallback","Arial","sans-serif"',
      ),
    density: (
      scale: -4,
    ),
  )
);
$website-theme-dark: mat.m2-define-dark-theme(
  (
    color: (
      primary: $website-primary,
      accent: $website-accent,
      warn: $website-warn,
    ),
    typography:
      mat.m2-define-typography-config(
        $font-family:
          '"ABCCameraPlain","ABCCameraPlain Fallback","Arial","sans-serif"',
      ),
    density: (
      scale: -4,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($website-theme);

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "ABCCameraPlain";
    src:
      url("/assets/fonts/ABCCameraPlain-Regular.woff2") format("woff2"),
      url("/assets/fonts/ABCCameraPlain-Regular.woff") format("woff"),
      url("/assets/fonts/ABCCameraPlain-Regular.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "ABCCameraPlain";
    src:
      url("/assets/fonts/ABCCameraPlain-Heavy.woff2") format("woff2"),
      url("/assets/fonts/ABCCameraPlain-Heavy.woff") format("woff"),
      url("/assets/fonts/ABCCameraPlain-Heavy.otf") format("opentype");
    font-weight: bold;
    font-style: normal;
  }
  @font-face {
    font-family: "ABCCameraPlain Fallback";
    src: local(Arial);
    size-adjust: 97%;
    ascent-override: 100%;
    descent-override: 30%;
    line-gap-override: 4%;
  }

  :root {
    -webkit-text-size-adjust: 100%;
    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;
    font-size: 100%;

    font-size: calc(100% * var(--a11y-font-size, 1));
    @apply scroll-smooth;

    --mat-select-trigger-text-tracking: 0px;
    --mat-form-field-container-height: 3rem;
    --mat-form-field-container-vertical-padding: 0.9rem;
    --mat-form-field-filled-with-label-container-padding-top: 1rem;
    --mat-option-label-text-tracking: 0px;
    --mat-select-trigger-text-size: calc(1rem * var(--a11y-font-size, 1));
    --mat-option-label-text-size: calc(1rem * var(--a11y-font-size, 1));

    --mdc-filled-text-field-label-text-tracking: 0px;
    --mdc-filled-button-container-shape: 9999px;
    --mdc-filled-text-field-container-color: theme("colors.white");
    --mat-select-placeholder-text-color: theme("colors.black");
    --mat-datepicker-calendar-body-label-text-weight: bold;
    --mat-datepicker-calendar-body-label-text-color: currentColor;
    --mdc-text-button-label-text-tracking: 0px;

    --default-gap: clamp(0.75rem, 2.5vw, 3rem);
  }

  body {
    scrollbar-gutter: stable both-edges;
    padding: 0;
    line-height: inherit;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    --vertical-space-small: 5vmin;
    --vertical-space-small: clamp(1.25rem, 5vmin, 2.5rem);
    --vertical-space: 10vmin;
    --vertical-space: clamp(2.5rem, 10vmin, 5rem);
    --vertical-space-big: 20vmin;
    --vertical-space-big: clamp(5rem, 20vmin, 10rem);
    @apply inverted-colors:text-white inverted-colors:bg-accent inverted-colors:has-[.show-nav]:bg-accent-900 dark:bg-gray-900 dark:text-white;
    // @apply lg:text-lg-a11y xl:text-xl-a11y;
    @apply text-sm md:text-base lg:text-lg xl:text-xl;
    @apply lg:a11yMedium:text-xl xl:a11yMedium:text-2xl;
    @apply lg:a11yLarge:text-2xl xl:a11yLarge:text-3xl;
  }

  @media (prefers-reduced-motion) {
    ::view-transition-group(*),
    ::view-transition-old(*),
    ::view-transition-new(*) {
      animation: none !important;
    }
  }

  app-slideout {
    @include mat.all-component-colors($website-theme-dark);
  }
}

@layer components {
  @keyframes fade-in {
    from {
      opacity: 0;
    }
  }

  @keyframes fade-out {
    to {
      opacity: 0;
    }
  }

  @keyframes slide-from-right {
    from {
      transform: translateX(100%);
    }
  }
  @keyframes slide-to-right {
    to {
      transform: translateX(100%);
    }
  }

  #outlet-bg {
    view-transition-name: aside-bg;
  }
  #outlet-aside {
    view-transition-name: aside;
  }
  ::view-transition-new(aside-bg):only-child {
    animation: 300ms cubic-bezier(0.4, 0, 0.2, 1) both fade-in;
  }
  ::view-transition-old(aside-bg):only-child {
    animation: 300ms 100ms cubic-bezier(0.4, 0, 0.2, 1) both fade-out;
  }
  ::view-transition-new(aside):only-child {
    animation:
      400ms cubic-bezier(0, 0, 0.2, 1) both fade-in,
      400ms cubic-bezier(0.4, 0, 0.2, 1) both slide-from-right;
  }
  ::view-transition-old(aside):only-child {
    animation:
      300ms cubic-bezier(0, 0, 0.2, 1) both fade-out,
      300ms cubic-bezier(0.4, 0, 0.2, 1) both slide-to-right;
  }

  .dynamic-text {
    > h3 {
      @apply heading mt-dynamic-xl mb-dynamic-xs;
    }
    > h4 {
      @apply heading-xs mt-dynamic mb-4;
    }
    > h5 {
      @apply heading-xxs mt-dynamic-xs mb-4;
    }
    > h6 {
      @apply mt-dynamic-xs mb-2 font-bold;
    }
    img {
      @apply mb-4 inline-block w-auto max-w-full;
    }
    p {
      @apply text-pretty;
      margin-bottom: 1.4em;
      &:last-child {
        margin-bottom: 0;
      }
      a {
        @apply link-underline--invert hover:link-underline--invert--hover;
        &[target="_blank"] {
          @apply relative;
          &::after {
            @apply inline pl-[0.2em];
            content: "↗";
          }
        }
      }
    }
    u {
      @apply decoration-accent inverted-colors:decoration-accent-300 underline decoration-1 underline-offset-4 dark:decoration-white dark:decoration-4;
    }
    ul,
    ol,
    table {
      margin-bottom: 1.4em;
    }
    ul {
      @apply list-outside list-disc pl-4;
    }
    ol {
      @apply list-outside list-decimal pl-4;
    }
    li {
      @apply mb-2;
    }
    table {
      tr {
      }
      td {
        @apply pr-2 lg:pr-3;
      }
    }
  }
  a.address-link {
    @apply link-underline--invert hover:link-underline--invert--hover;
    &[target="_blank"] {
      @apply relative mr-[0.8em];
      &::after {
        @apply inline;
        content: "↗";
      }
    }
  }
}

@layer utilities {
  .link-underline,
  .link-underline--invert {
    background-image: linear-gradient(
      180deg,
      transparent calc(100% - 0.05em - 1.5px),
      currentColor calc(100% - 0.05em - 1.5px),
      currentColor calc(100% - 0.05em),
      transparent calc(100% - 0.05em)
    );
    background-size: 0 100%;
    background-repeat: no-repeat;
    transition: background-size 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .link-underline--invert {
    background-size: 100% 100%;
    background-position: right;
  }
  .link-underline--hover {
    cursor: pointer;
    background-size: 100% 100%;
    &.link-underline--invert {
      background-size: 0 100%;
    }
  }
  .link-underline--invert--hover {
    cursor: pointer;
    background-size: 0 100%;
  }

  .verticalSpace {
    margin-top: var(--vertical-space, 10vmin);
    margin-bottom: var(--vertical-space, 10vmin);
  }
  .verticalSpaceBottom {
    margin-bottom: var(--vertical-space, 10vmin);
  }
  .verticalSpaceTop {
    margin-top: var(--vertical-space, 10vmin);
  }
  .verticalSpaceBig {
    margin-top: var(--vertical-space-big, 20vmin);
    margin-bottom: var(--vertical-space-big, 20vmin);
  }
  .verticalSpaceBigBottom {
    margin-bottom: var(--vertical-space-big, 20vmin);
  }
  .verticalSpaceBigTop {
    margin-top: var(--vertical-space-big, 20vmin);
  }

  .heading-xl {
    @apply xs:text-3xl xs:leading-tight text-balance text-2xl font-bold leading-tight sm:text-3xl md:text-4xl lg:-ml-px lg:text-5xl xl:-ml-1 xl:text-6xl 2xl:text-7xl;
  }
  .heading {
    @apply xs:text-2xl xs:leading-tight text-balance text-xl font-bold leading-tight sm:text-3xl md:text-4xl lg:-ml-px lg:text-5xl;
  }
  .heading-xs {
    @apply text-balance text-xl font-bold sm:text-2xl md:text-3xl lg:-ml-px lg:text-4xl;
    &.normal {
      @apply font-normal;
    }
  }
  .heading-xxs {
    @apply text-balance text-xl font-bold leading-tight md:text-2xl md:leading-tight lg:-ml-px lg:text-3xl;
    &.normal {
      @apply font-normal;
    }
  }
  .text-xs-a11y {
    @apply text-xs;
    @apply lg:a11yMedium:text-sm xl:a11yMedium:text-base;
    @apply lg:a11yLarge:text-lg xl:a11yLarge:text-xl;
  }
  .text-small-a11y {
    @apply text-xs md:text-sm lg:text-base 2xl:text-lg;
    @apply lg:a11yMedium:text-base xl:a11yMedium:text-xl;
    @apply lg:a11yLarge:text-lg xl:a11yLarge:text-2xl;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  // Layout grid for general layout
  .layout-grid {
    --layout-gap: var(--default-gap, clamp(0.75rem, 2.5vw, 3rem));
    --full: minmax(0, 1fr);
    --popout: minmax(0, 2.85rem);
    --feature: minmax(0, 6rem);
    --content: min(110rem, 100% - var(--layout-gap) * 2);

    display: grid;
    grid-template-columns:
      [full-width-start] var(--layout-gap) var(--full)
      [feature-start] var(--feature)
      [popout-start header-start] var(--popout)
      [content-start right-start] var(--content) [content-end]
      var(--popout) [popout-end]
      var(--feature) [feature-end]
      var(--full) [header-end] var(--layout-gap) [full-width-end right-end];

    grid-template-rows: min-content;
  }

  // content Grid for content
  .content-grid {
    --content-gap: var(--default-gap, clamp(0.75rem, 2.5vw, 3rem));
    --full: minmax(0, 1fr);
    --popout: minmax(0, 1fr);
    --feature: minmax(0, 1fr);
    --content: min(47rem, 100% - var(--content-gap) * 2);
    display: grid;
    grid-template-columns:
      [full-width-start left-start] var(--content-gap) var(--full)
      [feature-start feature-left-start] var(--feature)
      [popout-start popout-left-start] var(--popout)
      [left-end content-start feature-right-start popout-right-start] var(
        --content
      )
      [content-end feature-left-end popout-left-end right-start]
      var(--popout) [popout-end popout-right-end]
      var(--feature) [feature-end feature-right-end]
      var(--full) var(--content-gap) [full-width-end right-end];

    grid-template-rows: min-content;

    // @apply grid grid-cols-1 gap-5 md:grid-cols-12;
    // > * {
    //   @apply col-span-full md:col-span-10 md:col-start-2 2xl:col-span-6 2xl:col-start-4;
    // }
  }
  .layout-grid:has(.content-grid) .content-grid {
    --content-gap: 0rem;
  }

  .layout-left {
    grid-column: left;
  }
  .layout-right {
    grid-column: right;
  }

  .layout-grid > *,
  .content-grid > * {
    grid-column: content;
    // outline: 1px dashed rgba(255, 0, 0, 0.25);
  }
  .content-grid > .content-grid {
    grid-column: full-width;
  }

  .content-left {
    grid-column: left;
  }
  .content-right {
    grid-column: right;
  }

  .header {
    grid-column: header;
  }
  .popout {
    grid-column: popout;
  }
  .popout-left {
    grid-column: popout-left;
  }
  .popout-right {
    grid-column: popout-right;
  }

  .feature {
    grid-column: feature;
  }
  .feature-left {
    grid-column: feature-left;
  }
  .feature-right {
    grid-column: feature-right;
  }

  .full-width {
    grid-column: full-width;
  }
}

.cc-revoke,
.cc-window {
  @apply aspect-square;
  align-items: center;
  min-height: 2rem;
  line-height: 1.2;
  @apply bg-accent inverted-colors:bg-accent-900 font-sans text-white shadow-2xl;
  .cc-link:visited {
    @apply text-inherit;
  }
  .cc-btn {
    font-weight: normal;
    font-size: 1em;
    border: transparent;
    position: relative;
    background: white;
    color: black;
    &:hover {
      background: white;
      color: black;
    }
    padding: 0.5em 1.25em;
    &.cc-deny {
      outline: 2px solid currentColor;
      outline: 1.5px solid currentColor;
      outline-offset: -2px;
      outline-offset: -1.5px;
      color: white;
      &:hover {
        @apply bg-white/5;
      }
    }
  }
  z-index: 10;
  &.cc-floating {
    align-items: flex-end;
    @apply max-w-xs;
    @apply m-dynamic-xs bottom-0 right-0 p-4;
  }
  .cc-compliance {
    @apply items-end;
  }
  .cc-message {
    @apply text-lg font-bold leading-tight lg:text-xl lg:leading-tight;
  }
}
.cc-window {
  z-index: 11;
}
.cc-revoke.cc-bottom {
  @apply bottom-0 aspect-auto rounded-none;
}

@property --progress {
  syntax: "<number>"; /* <- defined as type number for the transition to work */
  initial-value: 0.5;
  inherits: false;
}
